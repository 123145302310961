.navbar {
  /* padding: 1em 40em !important; */
  width: 100%;
  border-radius: 0px !important;
  margin: 0 !important;
  top: 0px;
  position: "absolute";
  z-index: 10;
}

/* {{ borderRadius: '0px', padding: '1em 20em' }} */